//Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// 3rd Party dependencies
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

//Zonar Pattern Library
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule } from '@zonar-ui/auth';
import { SidenavModule } from '@zonar-ui/sidenav';
import {
  I18nService,
  TranslateService,
  ZonarUiI18nModule
} from '@zonar-ui/i18n';
import { ZuiFooterModule as ZonarUiFooterModule } from '@zonar-ui/footer';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';

// App-level Imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { CoreModule } from '@app/modules/core/core.module';
import { Translations } from '@app/services/i18n/translations.service';
import { languageFallbackMap } from '@app/services/i18n/language-fallback-map';
import { ResourceLoadState } from '@app/models/resource-load.state';
import { DeviceInformationComponent } from './device-information/device-information.component';
import { InstalledAppListComponent } from './installed-app-list/installed-app-list.component';
import { DeviceCommandsComponent } from './device-commands/device-commands.component';
import { FormsModule } from '@angular/forms';
import { TwoWayBindingDirective } from './twowaybindingdirective';

export function loadAppTranslations(
  translateService: TranslateService,
  i18nService: I18nService,
  translations: Translations
): () => Observable<any> {
  return () => {
    return translateService
      .use(
        i18nService.getLanguageInUse(
          environment.i18n.supportedLanguages,
          environment.i18n.defaultLanguage,
          languageFallbackMap,
          true
        )
      )
      .pipe(
        tap(() => {
          translations.translationsLoadState.next(
            ResourceLoadState.LOAD_SUCCESSFUL
          );
        }),
        switchMap((lang) => of(lang))
      );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DeviceInformationComponent,
    InstalledAppListComponent,
    DeviceCommandsComponent,
    TwoWayBindingDirective
  ],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      audience: environment.auth.audience,
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      max_age: 36000,
      useRefreshTokens: environment.auth.useRefreshTokens,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.appUrl}/*`,
          `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`
        ]
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    SidenavModule.forRoot({ stage: environment.authEnv || null }),
    ZonarUiAuthModule,
    ZonarUICoreModule,
    ZonarUiI18nModule,
    FormsModule,
    ZonarUiFooterModule,
    ZonarUiProgressSpinnerModule
  ],
  providers: [
    { provide: 'region', useValue: environment.region },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZonarOwnerIdInterceptor,
      multi: true
    },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    { provide: 'logoutReturnToUrl', useValue: window.location.origin },
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppTranslations,
      deps: [TranslateService, I18nService, Translations],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
