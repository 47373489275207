// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details

export const permissions = {
  defaultRedirectUrl: "/",
  defaultPermPrefix: "your_app_prefix",
  routes: [["/", "read:assets"]],
};
//TODO: change appName (should match datadog app name)
export const appName = "Zonar MDM";
// Typically we would use this to report to Datadog RUM if we have it installed.
export const appVersion = "0.0.1";
export const SIDENAV_HEADER_CONFIG = {
  displayName: true,
  avatarImageUrl: false
};

export const SIDENAV_FOOTER_CONFIG = {
  items: []
}
