import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceLocationService {

  constructor(
    private http: HttpClient,
  ) { }
  getDeviceLocation(deviceID: string) {
    let url = environment.appUrl + 'device_location?device_id=' + deviceID

    return this.http.get(url)
  }
}
