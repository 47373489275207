import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  constructor(
    private http: HttpClient
  ) { }
  private url = ''

  getDeviceInfoServ(device_identifier: string): Observable<any> {
    this.url = environment.appUrl + 'device_info?device_id=' + device_identifier

    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'application/json')
    if (device_identifier.length === 15 && !isNaN(Number(device_identifier))) {
      headers = headers.set('device-id-type', 'imei');
    } else if (device_identifier.length === 11 && isNaN(Number(device_identifier))) {
      headers = headers.set('device-id-type', 'serial-number')
    } else {
      return throwError(new HttpErrorResponse({
        status: 200,
        statusText: "Skipping Call due to failing API Conditions to make the call.",
        error: { "resultMessage": "Device Identifier should be a serial number or an IMEI." },
      }))
    }
    return this.http.get(this.url, { headers })
  }
}
