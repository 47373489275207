import { AuthService } from '@auth0/auth0-angular';
import { CookieService } from 'ngx-cookie-service';

import { ABTestService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { CoreCompanyApiService, CoreUserApiService, PermissionsService } from '@zonar-ui/auth';
import { environment } from '@environments/environment';
import { permissions } from '@environments/shared';


// solution from https://www.tektutorialshub.com/angular/angular-providers/#factory-provider-usefactory
export const zonarUiAnalyticsFactory = (
  cookieSvc: CookieService,
  coreCompanyApiSvc: CoreCompanyApiService,
  coreUserSvc: CoreUserApiService,
  authSvc: AuthService,
  region = environment.region
) => {
  if (region === 'NA') {
    return new ZonarUiAnalyticsService(
      environment.googleTagManager.id,
      environment.googleTagManager.auth,
      environment.googleTagManager.environment,
      new ABTestService(null, cookieSvc),
      new PermissionsService(
        coreCompanyApiSvc,
        coreUserSvc,
        authSvc,
        environment.auth.applicationId,
        permissions.defaultPermPrefix
      )
    );
  }

  //  if EU, or some other region we don't use Google Tag Manager, just return an empty object so GTM does not get instantiated
  return {};
};
