import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstalledAppListService {
  constructor(
    private http: HttpClient,
  ) { }
  private url = ''
  getInstalledAppListServ(deviceID: string): Observable<any> {
    this.url = environment.appUrl + 'installed_app_list?device_id=' + deviceID

    return this.http.get(this.url)
  }
}
