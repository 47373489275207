<div class="overarching">
    <div *ngIf="appListLoaded && !loadingDevice">
        <div class="refresh-container">
            <img [class]="{'refresh': ableToInstallOrUninstall, 'refresh-bad':!ableToInstallOrUninstall}"
                (click)="syncInstalledApps()" src="../assets/refresh_icon.png" />
            <p class="flavor-text refresh-text">Refresh/Sync Installed App Lists</p>
        </div>
        <h3 class="headers-for-device-info"><strong>Installed Applications On Tablet</strong></h3>
        <input type="text" [(ngModel)]="installedAppSearchBar" class="searchBar" (input)="filterInstalledTable()"
            maxlength="20" /><img class="clear-search" (click)="clearSearchForApp()"
            *ngIf="installedAppSearchBar.length > 0" src="../assets/clear.png" />

        <img *ngIf="!ableToInstallOrUninstall && !showLoadingIcon" src="../assets/Z_Radar_3.gif" width="5%"
            style="float:right; margin-top:20px;" />

        <br>
        <label class="flavor-text">Search by Application Name</label>
    </div>

    <div class="table" *ngIf="appListLoaded">

        <div class="table-header" *ngIf="appListLoaded">
            <div *ngFor="let column of columns" class="column-name">
                <strong>{{column}}</strong>
            </div>
        </div>

        <div class="tableForApps">
            <div *ngFor="let app of filteredData; trackBy: trackByAppId" class="table-row">
                <div class="table-cell">{{app.app_name}}</div>
                <div class="table-cell" id="version">{{app.app_version}}</div>
                <div class="table-cell"><button
                        [class]="{'uninstall-button': ableToInstallOrUninstall, 'badButton': !ableToInstallOrUninstall}"
                        (click)="uninstall_app(app.app_package)"><strong>UNINSTALL</strong></button>
                </div>
            </div>
        </div>
        <br>
    </div>

    <div *ngIf="appListLoaded && !loadingDevice">
        <br><br><br><br><br><br><br><br><br><br>
        <h3 class="headers-for-device-info"><strong>List of Applications to Install</strong></h3>
        <input type="text" [(ngModel)]="installSearchBar" class="searchBar" (input)="filterInstallListTable()"
            maxlength="20" /><img class="clear-search" (click)="clearInstallSearchForApp()"
            *ngIf="installSearchBar.length > 0" src="../assets/clear.png" />
        <img *ngIf="!ableToInstallOrUninstall && !showLoadingIcon" src="../assets/Z_Radar_3.gif" width="5%"
            style="float:right; margin-top:20px;" />
        <br>
        <label class="flavor-text">Search by Application Name</label>
    </div>

    <div class="table" *ngIf="appListLoaded">

        <div class="table-header" *ngIf="appListLoaded">
            <div *ngFor="let column of columnsForInstall" class="column-name">
                <strong>{{column}}</strong>
            </div>
        </div>

        <div class="tableForApps">
            <div *ngFor="let app of filteredInstallData; trackBy: trackByAppId" class="table-row">
                <div class="table-cell">{{app.app_name}}</div>
                <div class="table-cell"><button
                        [class]="{'uninstall-button': ableToInstallOrUninstall, 'badButton': !ableToInstallOrUninstall}"
                        (click)="install_app(app.app_package)"><strong>INSTALL</strong></button>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>

<div class="loading">
    <img src="../assets/Z_Radar_3.gif" *ngIf="(!appListLoaded && showLoadingIcon)"
        style="margin-left: 9%; margin-top:10%" />
</div>