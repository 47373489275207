import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InstallAppServiceService } from '../install-app-service.service';
import { InstalledAppListService } from '../installed-app-list.service';
import { SyncInstalledAppListService } from '../sync-installed-app-list.service';
import { UninstallAppService } from '../uninstall-app.service';
import { last } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

export interface App {
  app_name: string;
  app_version: string;
  app_package: string;
}

@Component({
  selector: 'app-installed-app-list',
  templateUrl: './installed-app-list.component.html',
  styleUrls: ['./installed-app-list.component.css']
})
export class InstalledAppListComponent implements OnChanges{
  columns = [
    'App Name',
    'App Version',
    'Uninstall Command'
  ]
  columnsForInstall = [
    'App Name',
    'Install Command'
  ]
  install_options: App[] = [
    { app_name: 'Wayfinder app', app_version: 'null', app_package: 'com.transfinder.wayfinder' },
    { app_name: 'Dropbox: Secure Cloud Storage', app_version: 'null', app_package: 'com.dropbox.android' },
    { app_name: 'Google Docs', app_version: 'null', app_package: 'com.google.android.apps.docs.editors.docs' },
    { app_name: 'Google Chrome: Fast  Secure', app_version: 'null', app_package: 'com.android.chrome' },
    { app_name: 'ContiConnect On-Site', app_version: 'null', app_package: 'com.continental.conticonnect.onsite' },
    { app_name: 'BusPlanner Turns', app_version: 'null', app_package: 'com.GeorefSystems_TRPT' },
    { app_name: 'Windy.com - Weather Forecast', app_version: 'null', app_package: 'com.windyty.android' },
    { app_name: 'Google Maps', app_version: 'null', app_package: 'com.google.android.apps.maps' },
    { app_name: 'SAP Concur', app_version: 'null', app_package: 'com.concur.breeze' },
    { app_name: 'Fr8Works Demo', app_version: 'null', app_package: 'com.google.enterprise.webapp.xc7c6ca22b4357415' },
    { app_name: 'GoCanvas Business Apps ADD CHROME', app_version: 'null', app_package: 'com.gocanvas' },
    { app_name: 'Skyward Mobile Access', app_version: 'null', app_package: 'com.skyward.mobileaccess' },
    { app_name: 'Google Calendar', app_version: 'null', app_package: 'com.google.android.calendar' },
    { app_name: 'Phone by Google', app_version: 'null', app_package: 'com.google.android.dialer' },
    { app_name: 'Microsoft Outlook', app_version: 'null', app_package: 'com.microsoft.office.outlook' },
    { app_name: 'YouTube', app_version: 'null', app_package: 'com.google.android.youtube' },
    { app_name: 'Slack', app_version: 'null', app_package: 'com.Slack' },
    { app_name: 'ADP Mobile Solutions', app_version: 'null', app_package: 'com.adpmobile.android' },
    { app_name: 'Truckers Against Trafficking', app_version: 'null', app_package: 'sherprog.walletcards.tat' },
    { app_name: 'Lytx Driver', app_version: 'null', app_package: 'com.lytx.androidapp' },
    { app_name: 'Gmail', app_version: 'null', app_package: 'com.google.android.gm' },
    { app_name: 'Microsoft Teams', app_version: 'null', app_package: 'com.microsoft.teams' },
    { app_name: 'Weather by WeatherBug', app_version: 'null', app_package: 'com.aws.android' },
    { app_name: 'Connecteam - All-in-One App', app_version: 'null', app_package: 'com.connecteamco.Connecteam.app' },
    { app_name: 'WEX Connect', app_version: 'null', app_package: 'com.wex.octane' },
    { app_name: 'Emergency - American Red Cross', app_version: 'null', app_package: 'com.cube.arc.hzd' },
    { app_name: 'Speedtest by Ookla', app_version: 'null', app_package: 'org.zwanoo.android.speedtest' },
    { app_name: 'NEXGEN Mobile for Tablet', app_version: 'null', app_package: 'com.nexgenam.nexgenV2' },
    { app_name: 'iSolved + Chrome', app_version: 'null', app_package: 'com.google.enterprise.webapp.x2c3bd3855d4bbca8' },
    { app_name: 'Bridgestone Toolbox Touch', app_version: 'null', app_package: 'com.bridgestone.toolboxtouch' },
    { app_name: 'HazMat Informer', app_version: 'null', app_package: 'com.truckscore.HazMat' },
    { app_name: 'Zonar Coach Driver App', app_version: 'null', app_package: 'com.zonarsystems.coach' },
    { app_name: 'Microsoft SharePoint', app_version: 'null', app_package: 'com.microsoft.sharepoint' },
    { app_name: 'Adobe Acrobat Reader: Edit PDF', app_version: 'null', app_package: 'com.adobe.reader' },
    { app_name: 'Carrier 360 by J.B. Hunt', app_version: 'null', app_package: 'com.jbhunt.JBH360Carrier' },
    { app_name: 'Google Drive', app_version: 'null', app_package: 'com.google.android.apps.docs' },
    { app_name: 'AirDroid Remote Support', app_version: 'null', app_package: 'com.sand.airsos' },
    { app_name: 'GasBuddy: Find and Pay for Cheap Gas and Fuel', app_version: 'null', app_package: 'gbis.gbandroid' },
    { app_name: 'Frontline Education', app_version: 'null', app_package: 'com.frontline.frontlinemobile' },
    { app_name: 'UKG Dimensions', app_version: 'null', app_package: 'com.kronos.dimensions.enterprise' },
    { app_name: 'SafeDriver360', app_version: 'null', app_package: 'com.geodataintelligence.safedriver360' },
    { app_name: 'Spare Driver', app_version: 'null', app_package: 'com.sparelabs.platform.driver.base' },
    { app_name: 'Salesforce', app_version: 'null', app_package: 'com.salesforce.chatter' },
    { app_name: 'Circuit Route Planner', app_version: 'null', app_package: 'com.underwood.route_optimiser' },
    { app_name: 'GoTo Resolve', app_version: 'null', app_package: 'com.goto.resolve.customer' },
    { app_name: 'One Key Web App', app_version: 'null', app_package: 'com.google.enterprise.webapp.xb2dbca0177dc5ae9' },
    { app_name: 'Splashtop Business Access', app_version: 'null', app_package: 'com.splashtop.remote.business' },
    { app_name: 'TripShot Driver', app_version: 'null', app_package: 'com.tripshot.driver' },
    { app_name: 'Splashtop SOS – Remote Support', app_version: 'null', app_package: 'com.splashtop.sos' },
    { app_name: 'Zum Driver', app_version: 'null', app_package: 'com.ridezum.zumdriver' },
    { app_name: 'CarriersEdge Mobile', app_version: 'null', app_package: 'com.carriersedge.CE' },
    { app_name: 'Workforce Mobile', app_version: 'null', app_package: 'com.staffapp' },
    { app_name: 'Passio Transit2', app_version: 'null', app_package: 'com.passio3.android' },
    { app_name: 'Paychex Flex', app_version: 'null', app_package: 'net.itx.paychex' },
    { app_name: 'DriveView by project44', app_version: 'null', app_package: 'com.project44.driview' },
    { app_name: 'USC Payroll', app_version: 'null', app_package: 'com.google.enterprise.webapp.x00d79da7b9ac9ad9' },
    { app_name: 'Pro-Tread', app_version: 'null', app_package: 'com.google.enterprise.webapp.x0c7cbd6d59e385d0' },
    { app_name: 'Yandex.Weather', app_version: 'null', app_package: 'ru.yandex.weatherplugin' },
    { app_name: 'BeyondTrust Support', app_version: 'null', app_package: 'com.bomgar.thinclient.android' },
    { app_name: 'TQL Carrier Dashboard', app_version: 'null', app_package: 'com.tql.carrierdashboard' },
    { app_name: 'Samsung Email', app_version: 'null', app_package: 'com.samsung.android.email.provider' },
    { app_name: 'TeamViewer QuickSupport', app_version: 'null', app_package: 'com.teamviewer.quicksupport.market' },
    { app_name: 'Network Cell Info Lite  Wifi', app_version: 'null', app_package: 'com.wilysis.cellinfolite' },
    { app_name: 'Network Utilities', app_version: 'null', app_package: 'com.myprog.netutils' },
    { app_name: 'AccuWeather: Weather Radar', app_version: 'null', app_package: 'com.accuweather.android' },
    { app_name: 'FourKites CarrierLink App', app_version: 'null', app_package: 'mobile.fourkites.com.carrierLink' },
    { app_name: 'TeamViewer Remote Control', app_version: 'null', app_package: 'com.teamviewer.teamviewer.market.mobile' },
    { app_name: 'Gboard - the Google Keyboard', app_version: 'null', app_package: 'com.google.android.inputmethod.latin' },
    { app_name: 'Via Driver', app_version: 'null', app_package: 'via.driver' },
    { app_name: 'Zello PTT Walkie Talkie', app_version: 'null', app_package: 'com.loudtalks' },
    { app_name: 'Microsoft Word: Edit Documents', app_version: 'null', app_package: 'com.microsoft.office.word' },
    { app_name: 'Zonar GTC Mobile', app_version: 'null', app_package: 'com.zonar.gtcx_live_maps' },
    { app_name: 'Vehicle Mobile Application', app_version: 'null', app_package: 'm.transport.Android' },
    { app_name: 'Microsoft PowerPoint', app_version: 'null', app_package: 'com.microsoft.office.powerpoint' },
    { app_name: 'BusPlanner Subs', app_version: 'null', app_package: 'com.busplanner.subs' },
    { app_name: 'Microsoft Excel: Spreadsheets', app_version: 'null', app_package: 'com.microsoft.office.excel' },
    { app_name: 'Waze Navigation  Live Traffic', app_version: 'null', app_package: 'com.waze' },
    { app_name: 'GoTo', app_version: 'null', app_package: 'com.logmein.gotoconnect' },
    { app_name: 'BeOn PTT', app_version: 'null', app_package: 'com.harris.rf.beonptt.android.ui' },
    { app_name: 'MobileIron Go', app_version: 'null', app_package: 'com.mobileiron.anyware.android' },
    { app_name: 'TimeWorksPlus Employee', app_version: 'null', app_package: 'com.swipeclock.mobile' },
    { app_name: 'UKG Workforce Central', app_version: 'null', app_package: 'com.kronos.mobile.android' },
    { app_name: 'Agility P.O.D.', app_version: 'null', app_package: 'com.dmsi.agilityPOD' },
    { app_name: 'TCP MobileClock', app_version: 'null', app_package: 'com.timeclockplus.MobileClock' },
    { app_name: 'RideView Companion + Chrome', app_version: 'null', app_package: 'lm.app.rideviewcompanion' },
    { app_name: 'LifeSaver - Distracted Driving', app_version: 'null', app_package: 'com.lifesaverapp' },
    { app_name: 'Splashtop Add-on: Samsung (Knox)', app_version: 'null', app_package: 'com.splashtop.streamer.addon.knox' },
    { app_name: 'UKG Ready', app_version: 'null', app_package: 'com.kronos.workforceready' },
    { app_name: 'UKG Ready Web App (AKR8138)', app_version: 'null', app_package: 'com.google.enterprise.webapp.xccd3f9f635720c67' },
    { app_name: 'SmartThings', app_version: 'null', app_package: 'com.samsung.android.oneconnect' },
    { app_name: 'Driver FreightBox', app_version: 'null', app_package: 'com.google.enterprise.webapp.x46ac84423f805438' },
    { app_name: 'IMPARGO DriverApp', app_version: 'null', app_package: 'com.impargo.driverapp' },
    { app_name: 'Budini Fleet', app_version: 'null', app_package: 'budini.fleet' },
    { app_name: 'LINQ + Chrome', app_version: 'null', app_package: 'com.google.enterprise.webapp.x89ca32511e64b661' },
    { app_name: 'Clock', app_version: 'null', app_package: 'com.google.android.deskclock' },
    { app_name: 'Knox Asset Intelligence', app_version: 'null', app_package: 'com.samsung.android.knox.dai' },
    { app_name: 'Knox E-FOTA', app_version: 'null', app_package: 'com.samsung.android.knox.efota' },
    { app_name: 'Knox Remote Support', app_version: 'null', app_package: 'com.sds.emm.sers' },
    { app_name: 'TimeSimplicity', app_version: 'null', app_package: 'com.swipeclock.timesimplicity' },
    { app_name: 'Knox Deployment', app_version: 'null', app_package: 'com.samsung.android.knox.enrollment' },
    { app_name: 'Device Care', app_version: 'null', app_package: 'com.samsung.android.lool' },
    { app_name: 'INSOFTDEV Mobility Driver', app_version: 'null', app_package: 'driver.insoftdev.androiddriver' },
    { app_name: 'Drive Axle', app_version: 'null', app_package: 'com.eleostech.driveaxle' },
    { app_name: 'Fleetrock', app_version: 'null', app_package: 'com.fleetrock.fleetrock' },
    { app_name: 'Driver Performance Management', app_version: 'null', app_package: 'com.microlise.dpm' },
    { app_name: 'PrePass', app_version: 'null', app_package: 'com.prepass.motion' },
    { app_name: 'Trucker Tools', app_version: 'null', app_package: 'com.salebug.truckstop' },
    { app_name: 'Onward', app_version: 'null', app_package: 'com.google.enterprise.webapp.x268518a97d37ebe3' },
    { app_name: 'CameraMatics Driver', app_version: 'null', app_package: 'com.pvcameras.provision' },
    { app_name: 'Wyoming 511', app_version: 'null', app_package: 'info.wyoroad.mobile.android' },
    { app_name: 'GlobalProtect', app_version: 'null', app_package: 'com.paloaltonetworks.globalprotect' },
    { app_name: 'Idaho 511', app_version: 'null', app_package: 'com.ibigroup.mobile.ta511id.android' },
    { app_name: 'Weather data & microclimate : Weather Underground', app_version: 'null', app_package: 'com.wunderground.android.weather' },
    { app_name: 'CrisisGo', app_version: 'null', app_package: 'com.linku.android.mobile_emergency.app.activity' },
    { app_name: 'Drivewyze PreClear Trucker App', app_version: 'null', app_package: 'com.drivewyze.agatha2' },
    { app_name: 'Trinium MC3', app_version: 'null', app_package: 'com.triniumtech.mc3' },
    { app_name: 'FM Mobile', app_version: 'null', app_package: 'com.argosoftware.fmmobile' },
    { app_name: 'Weigh My Truck', app_version: 'null', app_package: 'com.catscale.weighmytruck' },
    { app_name: 'Thermo King Connect', app_version: 'null', app_package: 'com.trane.tkconnect' },
    { app_name: 'Conti ECHO', app_version: 'null', app_package: 'com.conti.mobile.och2' },
    { app_name: 'EVIR Mobile', app_version: 'null', app_package: 'com.zonarsystems.evir.app' },
    { app_name: 'Briefing', app_version: 'null', app_package: 'flipboard.boxer.app' },
    { app_name: 'AccessMyLAN', app_version: 'null', app_package: 'com.asavie.modaaml' },
    { app_name: 'stratustime', app_version: 'null', app_package: 'com.centralservers.stratustime' },
    { app_name: 'FleetWatcher Driver', app_version: 'null', app_package: 'com.ew.fleetwatcherdriverdevice' },
    { app_name: 'USB Host Test', app_version: 'null', app_package: 'com.keuwl.usbhost' },
    { app_name: 'USB Serial Monitor', app_version: 'null', app_package: 'com.keuwl.usbserialmonitor' },
    { app_name: 'MacroPoint for Truckers', app_version: 'null', app_package: 'com.macropoint.application' },
    { app_name: 'Serial USB Terminal', app_version: 'null', app_package: 'de.kai_morich.serial_usb_terminal' },
    { app_name: 'ArriveCAN', app_version: 'null', app_package: 'ca.gc.cbsa.coronavirus' },
    { app_name: 'AZ Screen Recorder', app_version: 'null', app_package: 'com.hecorat.screenrecorder.free' },
    { app_name: 'FuelCloud', app_version: 'null', app_package: 'com.forix.fuelcloud.android' },
    { app_name: 'ParaScope', app_version: 'null', app_package: 'com.cts.ParaScope' },
    { app_name: 'Vysor - Android control on PC', app_version: 'null', app_package: 'com.koushikdutta.vysor' },
    { app_name: 'ASR Solutions', app_version: 'null', app_package: 'com.asrsolutions.ASRSolutions' },
    { app_name: 'Magnus Driver', app_version: 'null', app_package: 'com.vtvsolutions.mda.react' },
    { app_name: 'Service Kit', app_version: 'null', app_package: 'com.servicekit.mdvr.direct' },
    { app_name: 'Driver App for SpeedGauge', app_version: 'null', app_package: 'net.speedgauge.driverapp' },
    { app_name: 'PPro Driver App', app_version: 'null', app_package: 'com.producepro.driver' },
    { app_name: 'Origami Mobile Forms', app_version: 'null', app_package: 'com.origamirisk.mobileforms' },
    { app_name: 'TMW.Suite Go Driver', app_version: 'null', app_package: 'com.tmwsystems.tmwgodriver' },
    { app_name: 'TripVision®', app_version: 'null', app_package: 'com.noregon.tripVision' },
    { app_name: 'ContiConnect Driver', app_version: 'null', app_package: 'com.continental.android.conticonnectdriver' },
    { app_name: 'OSHA Safety - Laws and Regulations 1910 1926 1904', app_version: 'null', app_package: 'co.startmobile.oshasafety' },
    { app_name: 'Yotta Incident', app_version: 'null', app_package: 'net.cybersoft.yottaincident' },
    { app_name: 'Tailwind POD Complete', app_version: 'null', app_package: 'com.tailwind.app' },
    { app_name: '4 Road Service -  Truck Service Locator', app_version: 'null', app_package: 'www4roadservice.update' },
    { app_name: 'Daily Board', app_version: 'null', app_package: 'com.samsung.android.homemode' },
    { app_name: 'Ditat Mobile Dispatch', app_version: 'null', app_package: 'com.idatt' },
    { app_name: 'BusPlanner Assistant', app_version: 'null', app_package: 'com.busplanner.assistant' },
    { app_name: 'SRM Concrete - ADD ONELOGIN PORTAL', app_version: 'null', app_package: 'com.mobile.srm' },
    { app_name: 'BusConduct', app_version: 'null', app_package: 'com.incwebs.busconduct' },
    { app_name: 'Samsung My Files', app_version: 'null', app_package: 'com.sec.android.app.myfiles' },
    { app_name: 'My Tankstar Mobile App', app_version: 'null', app_package: 'com.tankstar.MyTankstar' },
    { app_name: 'Shop Inspection', app_version: 'null', app_package: 'com.swifttrans.shopinspection' },
    { app_name: 'Chatfinder', app_version: 'null', app_package: 'com.transfinder.chatfinder' },
    { app_name: 'eTAT', app_version: 'null', app_package: 'org.truckersagainsttrafficking.eld' },
  ]
  appList: App[] = [];
  filteredData: App[] = [];
  filteredInstallData: App[] = [];
  installedAppSearchBar: string = '';
  installSearchBar: string = '';
  @Input() loadingDevice: boolean = false;
  showLoadingIcon = false;
  @Input() device_id: string = '';
  appListLoaded = false;
  hideList = false;
  ableToInstallOrUninstall = true;

  @Output() commandSent = new EventEmitter<string>()
  @Output() processingCommand = new EventEmitter<boolean>()

  constructor(
    private appListServ: InstalledAppListService,
    private installServ: InstallAppServiceService,
    private uninstallServ: UninstallAppService,
    private syncInstalledAppsServ: SyncInstalledAppListService,
  ) { }

  ngOnInit(): void {
    this.processingCommand.emit(false)
  }

  async generate_app_list() {
    // console.log('changing processing command in app list')
    // console.log(this.appListLoaded)
    this.processingCommand.emit(true)
    this.appListLoaded = false
    try {
      this.appList = await this.appListServ.getInstalledAppListServ(this.device_id).pipe(last()).toPromise()
    }
    catch (error) {
      console.error('Error fetching data: ', error)
    }
    this.filteredData = this.appList
    if (this.installedAppSearchBar !== '') {
      this.filterInstalledTable()
    }
    this.filteredInstallData = this.install_options
    if (this.installSearchBar !== '') {
      this.filterInstallListTable()
    }
    console.log(this.appList)

    this.appListLoaded = true
    this.showLoadingIcon = false;
    this.ableToInstallOrUninstall = true;
    this.processingCommand.emit(false)
  }
  // Capture changes to the device_id value from the parent component. Specifically once a value is set in that that isn't null.
  ngOnChanges(changes: SimpleChanges) {
    console.log('Changes detected for installed app list.')
    if (typeof (changes['device_id']) !== 'undefined') {
      if (changes['device_id'].currentValue !== '') {
        console.log(this.device_id)
        this.showLoadingIcon = true;
        this.generate_app_list()
      }
      if (changes['device_id'].currentValue === '') {
        console.log('device id set to nothing')
        this.appListLoaded = false;
        this.processingCommand.emit(true)
      }
    }
  }

  // This filters the table based on a search bar value.
  filterInstalledTable() {
    this.installedAppSearchBar = this.installedAppSearchBar.trimStart();
    this.filteredData = this.appList.filter(app => {
      return app.app_name.toLowerCase().includes(this.installedAppSearchBar.toLowerCase())
    });
  }

  filterInstallListTable() {
    this.installSearchBar.trimStart();
    this.filteredInstallData = this.install_options.filter(app => {
      return app.app_name.toLowerCase().includes(this.installSearchBar.toLowerCase())
    })
  }

  trackByAppId(index: number, app: App): string | number {
    return app.app_package
  }

  printAppPackage(appPackage: string) {
    console.log('Sending uninstall command for app_package: ' + appPackage + " to device id: " + this.device_id)
  }

  clearSearchForApp() {
    this.installedAppSearchBar = ''
    this.filterInstalledTable()
  }

  clearInstallSearchForApp() {
    this.installSearchBar = ''
    this.filterInstallListTable()
  }

  async install_app(appPackage: string, count = 1) {
    console.log('Changing processing in install')
    this.processingCommand.emit(true)
    this.ableToInstallOrUninstall = false;
    let result = await this.installServ.postInstallCommand(this.device_id, appPackage).pipe(last()).toPromise();
    console.log('Result was: ', result);
    if (result['resultMessage'].includes("Successfully")) {
      let currentApp = this.install_options.find((app) => app.app_package === appPackage);
      let resultForEmit = 'Successfully sent install command for ' + currentApp?.app_name + ' app. Refreshing Installed app list. This may take up to 15 seconds.';
      this.commandSent.emit(resultForEmit);
    }
    else {
      let currentApp = this.install_options.find((app) => app.app_package === appPackage);
      if (count < 4) {
        var resultForEmit = 'Error when sending install command for ' + currentApp?.app_name + ' app. Attempting to send the command again.'
        this.commandSent.emit(resultForEmit);
        this.install_app(appPackage, count++)
      }
      else {
        var resultForEmit = 'Error when trying to send install command for the ' + currentApp?.app_name + '. Please try again later.'
        this.commandSent.emit(resultForEmit)
      }
    }
    setTimeout(() => {
      this.appListLoaded = false;
      this.showLoadingIcon = true;
      this.generate_app_list();
    }, 10000);
  }

  async uninstall_app(appPackage: string, count = 1) {
    console.log('Changing processing command in uninstall')
    this.processingCommand.emit(true)
    this.ableToInstallOrUninstall = false;
    let result = await this.uninstallServ.postUninstallCommand(this.device_id, appPackage).pipe(last()).toPromise();
    console.log('Result was: ', result);
    if (result['resultMessage'].includes("Successfully")) {
      let currentApp = this.appList.find((app) => app.app_package === appPackage);
      let resultForEmit = 'Successfully sent install command for ' + currentApp?.app_name + ' app. Refreshing Installed app list. This may take up to 15 seconds.';
      this.commandSent.emit(resultForEmit);
    }
    else {
      let currentApp = this.appList.find((app) => app.app_package === appPackage);
      if (count < 4) {
        var resultForEmit = 'Error when sending install command for ' + currentApp?.app_name + ' app. Trying to send the command again.'
        this.commandSent.emit(resultForEmit);
        this.uninstall_app(appPackage, count++)
      }
      else {
        var resultForEmit = 'Error when trying to send install command for the ' + currentApp?.app_name + '. Please try again later.'
        this.commandSent.emit(resultForEmit)
      }
    }
    setTimeout(() => {
      this.appListLoaded = false;
      this.showLoadingIcon = true;
      this.generate_app_list();
    }, 10000);
  }

  async syncInstalledApps() {
    console.log('Changing processing command in Sync')
    this.processingCommand.emit(true)
    this.ableToInstallOrUninstall = false;
    let result = await this.syncInstalledAppsServ.postSyncInstalledAppList(this.device_id).pipe(last()).toPromise();
    console.log('Result for sync was: ', result);
    if (result['resultMessage'] === '') {
      this.commandSent.emit('Syncing Installed App list now. This process can take up to 15 seconds to process and update.')
    } else {
      this.commandSent.emit(result['resultMessage'])
    }
    setTimeout(() => {
      this.appListLoaded = false;
      this.showLoadingIcon = true;
      this.generate_app_list();
    }, 10000);
  }
}
