// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: "dev",
  production: false,
  region: 'NA',
  appUrl: "https://us-west1-customer-care-working-9107.cloudfunctions.net/dev_",

  apiBase: {
    url: 'https://zonar-nonprod-dev.apigee.net'
  },

  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net/core/v1'
  },

  userPreferencesApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: "development",

  auth: {
    clientID: "C0V56hFO7QbrFTqzpLP3mvOlQS4G0SKd",
    domain: "zonar-dev.auth0.com",
    audience: "http://apidev.zonarsystems.net/",
    // These come from the Core APIs as your application is built.
    applicationId: "e04a7270-29f9-41cd-9e43-9d3f7a56fdb5",
    defaultZonarRole: '03f9d6a7-e6cd-4020-8e25-4359ad3a925b',
    useRefreshTokens: true,
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: "653634b4-f8fb-4a0d-ac02-4952ac46225d",
    clientToken: "pub4eefbc6863f610a963de8e37ce9b1d1d",
    site: 'us5.datadoghq.com',
    tagEnvironment: 'dev',
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de-DE', 'en-GB', 'it', 'fr-FR', 'de', 'es-ES'],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
