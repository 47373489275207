import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstallAppServiceService {

  constructor(
    private http: HttpClient,
  ) { }
  private url = ''

  postInstallCommand(device_identifier: string, app_package: string): Observable<any> {
    console.log("Sending install command for app ", app_package)
    console.log("Sending command to ", device_identifier)
    this.url = environment.appUrl + 'install_and_uninstall?device_id=' + device_identifier
    const headers = new HttpHeaders({
      'Command-Type': 'install',
      'App-Package': app_package,
    });
    const options = {
      headers: headers
    }
    console.log(options)
    return this.http.post(this.url, null, options)
  }
}
