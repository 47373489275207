import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UninstallAppService {

  constructor(
    private http: HttpClient,
  ) { }
  private url = ''

  postUninstallCommand(device_identifier: string, app_package: string): Observable<any> {
    console.log("Sending uninstall command for app package: ", app_package, ", to device id: ", device_identifier)
    this.url = environment.appUrl + "install_and_uninstall?device_id=" + device_identifier

    const headers = new HttpHeaders({
      "Command-Type": "uninstall",
      "App-Package": app_package
    });
    const options = {
      headers: headers
    }
    return this.http.post(this.url, null, options)
  }
}
