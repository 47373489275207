import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DeviceInformation } from '../device-information';
import { DeviceInfoService } from '../device-info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { last } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { TwoWayBindingDirective } from '../twowaybindingdirective';


@Component({
  selector: 'app-device-information',
  templateUrl: './device-information.component.html',
  styleUrls: ['./device-information.component.css'],
})
export class DeviceInformationComponent {
  ableToLoadNewDevice = true;
  deviceLoaded = false;
  loadingDevice = false;
  device_id_for_app_list: string = '';
  @Output() deviceIdForAppList = new EventEmitter<string>()
  showLoading = false;
  appListLoaded = false;
  device: any;
  imeiInput: string = '';
  isSerialNumber: boolean = this.imeiInput[0] === 'R';
  errorHappened = false;
  error_message = '';
  error_title = '';
  installedAppListComponentCommandsProcessing = false;
  deviceCommandsComponentCommandsProcessing = false;

  constructor(private deviceInformation: DeviceInfoService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log('Emitting empty string.')
    this.deviceIdForAppList.emit('')
  }

  async getDeviceInfo(deviceID: string) {

    this.device_id_for_app_list = ''
    this.loadingDevice, this.showLoading = true
    deviceID = deviceID.trim()
    this.ableToLoadNewDevice = false;
    this.appListLoaded = false;
    this.deviceLoaded = false

    console.log('Able to load new device: ', this.ableToLoadNewDevice, "\nImei Length: ", this.imeiInput.length, "\nInstalled App component commands processing: ", this.installedAppListComponentCommandsProcessing, "\nDevice Commands Component Processing: ", this.deviceCommandsComponentCommandsProcessing)

    device: DeviceInformation
    if (deviceID.length === 15 || deviceID.length === 11) {
      // console.log('grabbing device info for device identifier: ' + deviceID)
      try {
        const response = await this.deviceInformation.getDeviceInfoServ(deviceID).pipe(last()).toPromise()
        if ('resultMessage' in response) {
          this.error_message = response['resultMessage'];
          console.log(this.error_message)
          this.errorHappened = true;
          setTimeout(() => {
            this.clearResultText();
          }, 5000)
          console.log(this.errorHappened)
          console.log(this.error_message.length)
          this.changeDetectorRef.detectChanges()
        }
        else {
          this.device = this.processData(response)
          // console.log('Device below: ', this.device)
          this.deviceLoaded = true
          // console.log('Device loaded: ', this.deviceLoaded)
          this.loadingDevice = false;
          this.device_id_for_app_list = this.device.deviceID;
          this.deviceIdForAppList.emit(this.device.deviceID);
          console.log('Device ID for App list: ', this.device_id_for_app_list)
          this.changeDetectorRef.detectChanges()
        }
      } catch (error: any) {
        if (error instanceof HttpErrorResponse && error.status === 200) {
          const resultMessage = error.error && error.error.resultMessage ? error.error.resultMessage : 'Device Not Found.'
          this.error_message = resultMessage;
          this.errorHappened = true;
          setTimeout(() => {
            this.clearResultText()
          }, 5000)
        }
        else if (error instanceof HttpErrorResponse && error.status !== 200) {
          const resultMessage = error.error && error.error.resultMessage
          this.error_message = resultMessage;
          this.errorHappened = true;
          setTimeout(() => {
            this.clearResultText()
          }, 5000)
        }
        console.error('Error fetching data: ', error);

      }
    }
    else {
      this.error_message = "Sorry, This value is required to be an IMEI or a Serial Number.";
      this.errorHappened = true;
      setTimeout(() => {
        this.errorHappened = false;
      }, 5000)
    }
    this.showLoading = false;
    // console.log('Should show loading icon:', this.showLoading);
    this.ableToLoadNewDevice = true;
    this.changeDetectorRef.detectChanges()
  }
  private processData(data: any): any {
    let lastSeen = ''
    let currentEpoch = Math.floor(Number(new Date().getTime() / 1000));
    let deviceLastSeen = Math.floor(Number(data['deviceInfo']['lastConnection']) / 1000)
    let difOfTimes = currentEpoch - deviceLastSeen
    if (difOfTimes < 3600) {
      lastSeen = String(Math.floor(difOfTimes / 60) + 'm')
    } else if (difOfTimes > 3599 && difOfTimes < 86401) {
      lastSeen = String(Math.floor(difOfTimes / 3600) + 'h')
    } else {
      let temp = new Date(data['deviceInfo']['lastConnection'])
      lastSeen = temp.toDateString()
    }
    let mobileNumber = ''
    if (data['deviceInfo']['phoneNumber'] === '') {
      mobileNumber = "Not Found"
    } else {
      mobileNumber = "P" + data['deviceInfo']['phoneNumber']
    }
    let iccid = ''
    if (data['deviceInfo']['simId'] === '') {
      iccid = "Not Found"
    } else {
      iccid = data['deviceInfo']['simId']
    }
    console.log('data processed, sending back to normal function.')
    return new DeviceInformation(
      data['deviceInfo']['imei'],
      iccid,
      data['deviceInfo']['serialNumber'],
      mobileNumber,
      data['deviceInfo']['deviceID'],
      data['deviceInfo']['assignedProfiles'],
      lastSeen,
      data['deviceInfo']['licenseStatus'],
      data['deviceInfo']['deviceName'],
      data['deviceInfo']['battery'],
      data['deviceInfo']['osVersion'],
      data['orgInfo']['orgName'],
      data['orgInfo']['orgCode'],
      data['groupInfo']['groupList'],
    )
  }
  printAppPackage(appPackage: string, device_id: string) {
    console.log('Sending uninstall command for app_package: ' + appPackage + " to device id: " + device_id)
  }

  clearSearchForImeiSerial() {
    this.imeiInput = ''
  }

  clearResultText() {
    // console.log('Clearing result text')
    this.error_message = '';
    this.errorHappened = false;
    this.changeDetectorRef.detectChanges()
  }

  processChildComponents(data: string) {
    // console.log("Data from child component was: ", data)
    this.error_message = data;
    this.errorHappened = data.includes('Error')
    setTimeout(() => this.clearResultText(), 5000);
  }

  processingInstalledAppComponentCommands(data: boolean) {
    this.installedAppListComponentCommandsProcessing = data
    // console.log('Command processing is: ', this.installedAppListComponentCommandsProcessing)
  }

  processingDeviceCommandsComponentCommands(data: boolean) {
    // console.log('apply latest profile routed here.')
    this.deviceCommandsComponentCommandsProcessing = data
  }
}
