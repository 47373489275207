export const languageFallbackMap = [
    {
      languageAbbreviation: 'de',
      useLanguage: 'de-DE'
    },
    {
      languageAbbreviation: 'it',
      useLanguage: 'it-IT'
    },
    {
      languageAbbreviation: 'es',
      useLanguage: 'es-ES'
    },
    {
      languageAbbreviation: 'fr',
      useLanguage: 'fr-FR'
    },
    {
      languageAbbreviation: 'en',
      useLanguage: 'en-GB'
    }
  ];
    