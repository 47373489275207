export const isLocalDevEnv = () => {
    // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
    return (
      window?.location?.origin?.includes('localhost:4200') ||
      window?.location?.origin?.includes('local.dev')
    );
  };
  
  // Source: https://stackoverflow.com/a/53943624
  export function repeatTimes(times: number) {
    return {
      [Symbol.iterator]: function* () {
        for (let i = 0; i < times; i++, yield) {}
      }
    };
  }
  