import { logging } from "@angular-devkit/core";

export class DeviceInformation {
    imeiInfo: string;
    iccidInfo: string;
    serialNumber: string;
    phoneNumber: string;
    deviceID: string;
    assignedProfileNameList: [];
    lastConnectionDate: string;
    licenseStatus: string;
    deviceName: string;
    batteryLevel: string;
    osVersion: string;
    deviceOrgName: string;
    deviceOrgCode: string;
    groupNameList: [];

    constructor(
        imeiInfo: string,
        iccidInfo: string,
        serialNumber: string,
        phoneNumber: string,
        deviceID: string,
        assignedProfileNameList: [],
        lastConnectionDate: string,
        licenseStatus: string,
        deviceName: string,
        batteryLevel: string,
        osVersion: string,
        deviceOrgName: string,
        deviceOrgCode: string,
        groupNameList: [],
    ) {
        this.imeiInfo = imeiInfo;
        this.iccidInfo = iccidInfo;
        this.serialNumber = serialNumber;
        this.phoneNumber = phoneNumber;
        this.deviceID = deviceID;
        this.assignedProfileNameList = assignedProfileNameList;
        this.lastConnectionDate = lastConnectionDate;
        this.licenseStatus = licenseStatus;
        this.deviceName = deviceName;
        this.batteryLevel = batteryLevel;
        this.osVersion = osVersion;
        this.deviceOrgName = deviceOrgName;
        this.deviceOrgCode = deviceOrgCode;
        this.groupNameList = groupNameList;
    }
}