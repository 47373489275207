import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";
import { AppComponent } from "src/app/app.component";

const routes: Routes = [
  // TODO: add desired component below to be default route
  // {
  //   path: "",
  //   component: AppComponent,
  //   canActivate: [AuthGuard],
  // },

  // Lazy-loaded standalone component
  {
    path: "status",
    loadComponent: () =>
      import("./containers/status-container/status-container.component").then(
        (m) => m.StatusContainerComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
