import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncInstalledAppListService {

  constructor(
    private http: HttpClient,
  ) { }
  private url = ''


  postSyncInstalledAppList(device_identifier: string): Observable<any> {
    this.url = environment.appUrl + "current_profile?device_id=" + device_identifier

    return this.http.post(this.url, null)
  }

}
