<div id="errorOrSuccessMessage" [class]="{'errors': errorHappened, 'success': !errorHappened, 'nothing': error_message.length === 0}">
    <p id="resultMessage" class="resultMessage">{{error_message}} <img
            [class]="{'errorsButton': errorHappened, 'successButton': !errorHappened}" *ngIf="error_message.length > 0"
            (click)="clearResultText()" src="../assets/clear.png" />
    </p>
</div>

<div class="imeiInputSection">
    <label for="IMEI"><strong>Device Identifier</strong> </label><br>
    <input id="imeiInputSearch" class="searchBar" [(ngModel)]="imeiInput" type='text' maxlength="20" (valueChange)="imeiInput = $event" /> <img class="clear-search"
        (click)="clearSearchForImeiSerial()" *ngIf="imeiInput.length > 0" src="../assets/clear.png" />
    <br>
    <label class="flavor-text">Search for IMEI or Serial Number</label>
    <div>
        <button id="imeiInputButton" (click)="getDeviceInfo(imeiInput)" type="submit"
            [class]="{'button': ableToLoadNewDevice && (imeiInput.length === 15 || imeiInput.length === 11) && !installedAppListComponentCommandsProcessing && !deviceCommandsComponentCommandsProcessing, 'badButton': !ableToLoadNewDevice || (imeiInput.length !== 15 && imeiInput.length !== 11) || installedAppListComponentCommandsProcessing || deviceCommandsComponentCommandsProcessing}"
            [disabled]="!ableToLoadNewDevice || (imeiInput.length !== 15 && imeiInput.length !== 11)|| installedAppListComponentCommandsProcessing || deviceCommandsComponentCommandsProcessing"><strong>SHOW</strong></button>
    </div>
</div>

<div *ngIf="deviceLoaded" class="box">
    <h2 class="headers-for-device-info"><strong>IMEI: {{device.imeiInfo}}</strong></h2>
    <h3 class="headers-for-device-info2"><strong>Device Info</strong></h3>
    <div class="table-for-device-info">
        <p class="items-for-device-info"><strong>IMEI: </strong>{{device.imeiInfo}}</p>
        <p class="items-for-device-info"><strong>ICCID: </strong>{{device.iccidInfo}}</p>
        <p class="items-for-device-info"><strong>Serial Number:</strong> {{device.serialNumber}}</p>
        <p class="items-for-device-info"><strong>Mobile Number:</strong> {{device.phoneNumber}}</p>
        <p class="items-for-device-info"><strong>Assigned Profile List:</strong><br>
            &nbsp;&nbsp;&nbsp;{{device.assignedProfileNameList}}</p>
        <p class="items-for-device-info"><strong>Last Connection:</strong>
            {{device.lastConnectionDate}}</p>
        <p class="items-for-device-info"><strong>License Status:</strong> {{device.licenseStatus}}</p>
        <p class="items-for-device-info"><strong>Device Type:</strong> {{device.deviceName}}</p>
        <p class="items-for-device-info"><strong>Battery Level:</strong> {{device.batteryLevel}}%</p>
        <p class="items-for-device-info"><strong>OS Version:</strong> {{device.osVersion}}</p>
    </div>
    <h3 class="headers-for-device-info2"><strong>Organization Info</strong></h3>
    <div class="table-for-device-info">
        <p class="items-for-device-info"><strong>Orgnization Name:</strong> {{device.deviceOrgName}}
        </p>
        <p class="items-for-device-info"><strong>Organization Code:</strong> {{device.deviceOrgCode}}
        </p>
    </div>
    <h3 class="headers-for-device-info2"><strong>Group Info</strong></h3>
    <div class="table-for-device-info">
        <strong>&nbsp;&nbsp;&nbsp;Group Name List:</strong>
        <div *ngFor="let group of device.groupNameList">
            <p class="items-for-device-info">&nbsp;&nbsp;&nbsp;{{group}} </p>
        </div>
    </div>
</div>
<div class="loading">
    <img src="../assets/Z_Radar_3.gif" *ngIf="showLoading" style="margin-left:15%" />
</div>

<app-installed-app-list [loadingDevice]="loadingDevice" [device_id]="device_id_for_app_list"
    (commandSent)="processChildComponents($event)"
    (processingCommand)="processingInstalledAppComponentCommands($event)">
</app-installed-app-list>
<app-device-commands [device_id]="device_id_for_app_list" (commandSent)="processChildComponents($event)"
    (processingCommand)="processingDeviceCommandsComponentCommands($event)">
</app-device-commands>