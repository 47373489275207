import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ApplyLatestProfileService } from '../apply-latest-profile.service';
import { DeviceLocationService } from '../device-location.service';
import { last } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

export interface DeviceLocation {
  lattitude: string;
  longitude: string;
  timestamp: string;
}

@Component({
  selector: 'app-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.css']
})
export class DeviceCommandsComponent {
  loaded = false;
  @Input() device_id = '';
  applying = false;
  locationLoaded = false;
  deviceLocation: DeviceLocation = {
    lattitude: '',
    longitude: '',
    timestamp: ''
  }


  @Output() commandSent = new EventEmitter<string>()
  @Output() processingCommand = new EventEmitter<boolean>()

  constructor(private applyLatestProfServ: ApplyLatestProfileService, private loccationServ: DeviceLocationService) { }

  ngOnInit(): void {
    this.processingCommand.emit(false)
  }

  async applyLatestProfile() {
    this.applying = true;
    this.processingCommand.emit(this.applying)
    let response = await this.applyLatestProfServ.postApplyLatestProfile(this.device_id).pipe(last()).toPromise()
    if (response['resultMessage'] === '') {
      this.commandSent.emit('Apply Latest profile command successfully sent.')
    }
    this.applying = false;
    this.processingCommand.emit(this.applying)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof (changes['device_id']) !== 'undefined') {
      if (changes['device_id'].currentValue !== '') {
        this.loaded = true;
        // this.getLocation()
      } else {
        this.loaded = false;
      }
    }
  }
  async getLocation() {
    let response = await this.loccationServ.getDeviceLocation(this.device_id).pipe(last()).toPromise()
    if (response.hasOwnProperty('lat')) {
      this.processData(response)
    } else {
      this.commandSent.emit('Error occurred when attempting to get device location.')
      console.log(response)
    }
  }

  private processData(data: any) {
    this.deviceLocation.lattitude = data['lat'];
    this.deviceLocation.longitude = data['long'];
    this.deviceLocation.timestamp = data['timestamp']
  }
}
