<div *ngIf="loaded" class="overarching">
    <h2 class="headers-for-device-info"><strong>Other Tablet Commands</strong></h2>
    <div class="table">
        <p class="column items-for-device-info">&nbsp;<strong>Apply Latest Profile</strong></p>
        <div class="column">
            <button (click)="applyLatestProfile()"
                [class]="{'goodButton': !applying,'badButton': applying}"><strong>APPLY</strong></button>
        </div>
    </div>
    <!-- <div class="location" *ngIf="!locationLoaded">
    <h2 class="headers-for-device-info " style="margin-top:20px"><strong>Location of Tablet</strong></h2>
    <p>Lattitude: </p>
    <p>Longitude: </p>
    <p>Time Stamp: </p>
</div> -->

</div>